.game__table {
  width: 500px;
  height: 500px;
  border-collapse: collapse;
  margin: 20px auto;
}

.main {
  background-color: #d9afd9;
  background-image: linear-gradient(0deg, #d9afd9 0%, #97d9e1 100%);
  height: 100vh;
  display: flex;
  justify-content: center;
  padding: 50px;
}

.game__table td {
  width: 33.33%;
  height: 33.33%;
  text-align: center;
  vertical-align: middle;
  font-size: 2em;
  border: 1px solid #000;
  cursor: pointer;
}

.game__table td:hover {
  background-color: #f0f0f0;
}

.cell button {
  border: none;
  background-color: transparent;
  font-size: 25px;
}

.main__gameSection {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.btn {
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
  border: 1px solid #212121;
  padding: 10px;
  border-radius: 15px;
  font-size: 20px;
  width: 500px;
  cursor: pointer;
}
.btn:hover {
  background-color: red;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
}

.name__inputSection {
  text-align: center;
  max-width: 400px;
}

.input {
  border: 1px solid #212121;
  border-radius: 10px;
  padding: 5px;
  margin: 5px;
}
.name__inputs {
  display: flex;
  flex-direction: column;
}

.setNameBtn {
  width: 100%;
  padding: 5px;
  border-radius: 10px;
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);
  border: 1px solid #212121;
}

@media (max-width: 320px) {
  .game__table,
  .btn {
    width: 100%;
  }
}
@media (max-width: 617px) {
  .game__table,
  .btn {
    width: 100%;
  }
}
